<template>
  <div class="flex min-h-screen overflow-hidden surface-0 align-items-center justify-content-center min-w-screen">
    <div class="grid p-2 justify-content-center lg:p-0" style="min-width: 80%">
      <div class="col-12 xl:col-6 tw-shadow-xl tw-border-2 tw-border-gray-200 tw-rounded-2xl">
        <div class="w-full h-full px-4 m-0 py-7">
          <div class="mt-5 text-center col-12 xl:mt-0">
            <router-link :to="{ name: 'landing' }">
              <img
                src="~@/assets/images/logo.png"
                alt="Sakai logo"
                class="mx-auto"
                style="height: 60px"
              />
            </router-link>
          </div>

          <div class="text-center tw-mb-16 tw-mt-10">
            <h1 class="tw-text-4xl tw-font-bold">Sign in to continue</h1>
          </div>

          <div class="w-full mx-auto md:w-10">
            <label for="email1" class="block mb-2 text-xl font-medium text-900">Email</label>
            <InputText
              id="email1"
              v-model="email"
              type="text"
              class="w-full mb-3"
              placeholder="Email"
              style="padding: 1rem"
            />

            <label for="password1" class="block mb-2 text-xl font-medium text-900">
              Password
            </label>

            <Password
              id="password1"
              v-model="password"
              placeholder="Password"
              :toggleMask="true"
              class="w-full mb-3"
              inputClass="w-full"
              :feedback="false"
              inputStyle="padding:1rem">
            </Password>
            <div class="text-right ">
              <router-link :to="{name: 'forgot-password'}" class="font-bold no-underline ml-2 cursor-pointer text-primary">
                Forgot Password?
              </router-link>
            </div>

            <div class="form-group mt-3">
              <vue-recaptcha
                ref="recaptcha"
                class="recaptcha"
                :sitekey="sitekey"
                :loadRecaptchaScript="true"
                @verify="onVerify"
                @expired="onExpired">
              </vue-recaptcha>
            </div>

            <Button
              label="Sign In"
              class="w-full p-3 text-xl tw-mt-12"
              @click="submitLogin">
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCustomAuth } from "@/composables/auth";
import {  ref } from "vue";
import { VueRecaptcha } from 'vue-recaptcha'
import { useToast } from "primevue/usetoast";

export default {
  setup() {
    const { login } = useCustomAuth();

    const email = ref("");
    const password = ref("");
    const checked = ref(false);
    const recaptcha_resp = ref("");
    const toast = useToast();
    const recaptcha = ref(null);

    const submitLogin = () => {
      if (!email.value || !password.value) {
        toast.add({
          severity: 'error',
          summary: 'Authentication Failed!',
          detail: 'Email/Password can not be empty!',
          life: 2000
        });
        return
      }

      if (!recaptcha_resp.value) {
        toast.add({
          severity: 'error',
          summary: 'Authentication Failed!',
          detail: 'Captcha can not empty!',
          life: 2000
        });
        return
      }

      login({
        email: email.value,
        password: password.value,
        recaptcha_resp: recaptcha_resp.value,
      });

      recaptcha.value.reset();
    };

    return { email, password, checked, login, recaptcha_resp, recaptcha, submitLogin };
  },
  components: {
    VueRecaptcha,
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
  data() {
    return {
      sitekey: process.env.VUE_APP_RECAPTCHA_KEY,
    };
  },
  methods: {
    onVerify: function (recaptchaToken) {
      this.recaptcha_resp = recaptchaToken;
    },
    onExpired: function () {
      this.$refs.recaptcha.reset();
      this.recaptcha_resp = '';
    },
  },
};
</script>

<style scoped></style>
